import { Accordion, Box, Divider, Flex, Group, Modal, Space, createStyles } from '@mantine/core'
import { useLocalStorage } from '@mantine/hooks'
import { IconChevronDown } from '@tabler/icons'
import { useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'

import {
  UNAUTHENTICATED_MOBILE_MENU_ITEMS,
  getAuthenticatedMobileMenuItems,
} from '~/components/layout/constants'
import { MantineNextLink } from '~/components/shared/MantineNextLink'
import { ThatchButton } from '~/components/shared/ThatchButton'
import { Typography } from '~/components/shared/text/Typography'
import { fetchSubscriptionUpdates } from '~/endpoints/board'
import { ProfileSummaryType } from '~/endpoints/model'
import {
  FIVE_SECONDS_IN_MILLI,
  LOCAL_STORAGE_MOST_RECENT_UPDATE,
  REACT_QUERY_SUBSCRIPTION_UPDATES_KEY,
  TEN_MINUTES_IN_MILLI,
} from '~/utils/constants'
import { TopMenuItem } from '~/utils/types'
import { SearchBox } from '../home/SearchBox'

interface MobileMenuProps {
  onClose: () => void
  isOpened: boolean
  isAuthenticated: boolean
  profile?: ProfileSummaryType
  onSearch: () => void
  handleClickItem: (item: TopMenuItem) => Promise<void>
}

const useStyle = createStyles(_theme => ({
  accordionItem: {
    border: 'none',
  },
  accordionControl: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 31.5,
    paddingBottom: 31.5,
  },
  accordionLabel: {
    padding: 0,
  },
  accordionContent: {
    padding: '18px 16px 8px 16px',
  },
}))

export const MobileMenu: React.FC<MobileMenuProps> = ({
  onClose,
  isAuthenticated,
  isOpened,
  onSearch,
  handleClickItem,
  profile,
}) => {
  const { classes } = useStyle()
  const { data: updates } = useQuery({
    queryKey: [REACT_QUERY_SUBSCRIPTION_UPDATES_KEY],
    queryFn: () => fetchSubscriptionUpdates(10),
    staleTime: TEN_MINUTES_IN_MILLI,
    gcTime: TEN_MINUTES_IN_MILLI,
    retry: 3,
    retryDelay: FIVE_SECONDS_IN_MILLI,
  })
  const [recentUpdate] = useLocalStorage({
    key: LOCAL_STORAGE_MOST_RECENT_UPDATE,
    defaultValue: '',
  })
  const [showDot, setShowDot] = useState<boolean>(false)

  useEffect(() => {
    const current = updates ?? []
    if (current.length > 0 && (recentUpdate ? current[0].timestamp != recentUpdate : true)) {
      setShowDot(true)
    }
  }, [recentUpdate, updates])

  const menuItems = isAuthenticated
    ? getAuthenticatedMobileMenuItems(profile, showDot)
    : UNAUTHENTICATED_MOBILE_MENU_ITEMS

  const handleClick = async (item: TopMenuItem) => {
    await handleClickItem(item)
    onClose()
  }

  const renderItems = (item: TopMenuItem, index: number) => {
    switch (item.type) {
      case 'divider':
      case 'childrenDivider':
        return (
          <Divider
            key={`${item.type}-${index}`}
            color={item.type === 'childrenDivider' ? 'rgba(0, 0, 0, 0.3)' : 'appBlack.0'}
          />
        )
      case 'accordion':
        return (
          <Accordion
            key={`${item.label?.toString()}-${index}`}
            classNames={{
              item: classes.accordionItem,
              control: classes.accordionControl,
              label: classes.accordionLabel,
              content: classes.accordionContent,
            }}
            chevron={
              <IconChevronDown
                size={18}
                color="black"
              />
            }
          >
            <Accordion.Item value={item.label?.toString() ?? ''}>
              <Accordion.Control>
                <Typography
                  variant="eyebrow"
                  transform="uppercase"
                >
                  {item.label?.toString() ?? ''}
                </Typography>
              </Accordion.Control>
              <Accordion.Panel>
                {item.accordionItems?.map((accordionItem, accordionItemIndex) => (
                  <Box
                    key={`${accordionItem.label?.toString}-${accordionItemIndex}`}
                    mb={32}
                  >
                    {renderItems(accordionItem, accordionItemIndex)}
                  </Box>
                ))}
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
        )
      case 'link':
        return (
          <Box
            key={`${item.label?.toString()}-${index}`}
            component={MantineNextLink}
            href={item.href || ''}
            target={item.linkTarget}
            onClick={onClose}
          >
            <Flex
              align="center"
              gap={6}
            >
              {item.customNode ? (
                item.customNode
              ) : (
                <Group>
                  {item.icon}
                  <Typography variant="title">{item.label}</Typography>
                </Group>
              )}

              {item.isNew && (
                <Flex
                  bg="appFuscia.0"
                  align="center"
                  justify="center"
                  sx={{ width: 41, height: 23, borderRadius: '27px' }}
                >
                  <Typography
                    variant="tag"
                    color="appWhite.0"
                  >
                    New
                  </Typography>
                </Flex>
              )}
            </Flex>
          </Box>
        )
      case 'actionButton':
        return (
          <Box
            key={`${item.label?.toString()}-${index}`}
            mx={16}
          >
            <ThatchButton
              fullWidth
              loading={item.loading}
              variant={item.isPrimary ? 'filled' : 'outline'}
              sx={{ border: `1px solid black` }}
              onClick={() => handleClick(item)}
              size="xlarge"
              label={item.label}
            />
          </Box>
        )
      case 'action':
        return (
          <Box
            key={`${item.label?.toString()}-${index}`}
            onClick={() => handleClick(item)}
          >
            {item.customNode ? (
              item.customNode
            ) : (
              <Group>
                {item.icon}
                <Typography variant="title">{item.label}</Typography>
              </Group>
            )}
          </Box>
        )
      case 'space':
        return (
          <Space
            key={`${item.type}-${index}`}
            h={item.spaceSize}
          />
        )
      default:
        // Ignore unknown item types.
        break
    }
  }

  return (
    <Modal
      opened={isOpened}
      onClose={onClose}
      fullScreen
      trapFocus={false}
      // withCloseButton={false}
      styles={{
        root: { zIndex: 1002 },
        header: {
          padding: '32px 16px',
          paddingBottom: 0,
          marginBottom: 0,
        },
        content: {
          paddingBottom: 120,
        },
        body: {
          paddingBottom: 80,
        },
      }}
      padding={0}
    >
      <Space h={20} />
      <Box px={16}>
        <SearchBox placeholder='Explore Thatch' source='mobile_main_menu' onSearch={onClose}/>
      </Box>
      <Space h={40} />

      {menuItems.map(renderItems)}
    </Modal>
  )
}
