import {
  Modal,
  createStyles,
} from '@mantine/core'

import { newThatchDialogStyle } from '~/components/shared/dialog'
import { mobileTypography } from '~/theme'
import { Title, TitleItalic } from '../shared/text/Title'
import { SearchBox } from './SearchBox'

const useStyle = createStyles(theme => ({
  modalHeader: {
    padding: '32px 16px',
    marginBottom: 0,
  },
  modalBody: {
    padding: '0px 16px',
  },
  root: {
    border: `1px solid ${theme.colors.appBlack}`,
    background: theme.colors.appWhite,
    height: 60,
    width: '100%',
  },
  input: {
    ...mobileTypography.title2,
    border: 'none',
    height: 58,
    paddingLeft: 16,

    '::placeholder': {
      color: theme.colors.appPlaceholder,
    },
  },
  rightSection: {
    width: 'auto',
    marginRight: 16,
  },
  mobileSearchButton: {
    width: 30,
    height: 30,
    borderRadius: 15,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.appBlack,
  },
  item: {
    padding: '9px 0px',

    '[data-selected], &[data-hovered], :hover, :active, ::selection, :focus': {
      backgroundColor: theme.colors.appYellow,
      cursor: 'pointer',
    },
  },
}))

interface MobileSearchModalProps {
  onClose: () => void
}
export const MobileSearchModal: React.FC<MobileSearchModalProps> = ({
  onClose,
}) => {
  const { classes: dialogClasses } = newThatchDialogStyle({})
  const { classes } = useStyle()

  return (
    <Modal
      opened
      onClose={onClose}
      fullScreen
      classNames={{
        root: dialogClasses.modalRoot,
        close: dialogClasses.modalCloseButton,
        header: classes.modalHeader,
        body: classes.modalBody,
      }}
      title={<Title variant='h2'><TitleItalic>Search</TitleItalic> Thatch</Title>}
      trapFocus
    >
      <SearchBox placeholder='Explore Thatch' autofocus onSearch={onClose} source='mobile_search_modal'/>
    </Modal>
  )
}
