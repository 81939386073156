import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { Input, CloseButton, FocusTrap } from '@mantine/core';
import { SvgIcon, SvgIconType } from '~/components/shared/image/SvgIcon'
import { ThatchButton } from '~/components/shared/ThatchButton'
import { getHotkeyHandler } from '@mantine/hooks';
import { useAnalytics } from '~/context';

export function SearchBox({ placeholder = 'Explore guides, itineraries and experts', autofocus = false, onSearch, source }: { placeholder?: string, autofocus?: boolean, onSearch?: () => void, source?: string }) {
    const [query, setQuery] = useState('')
    const router = useRouter()
    const thatchSegment = useAnalytics()

    const pageQuery = router.query.query as string

    useEffect(() => {
        if (pageQuery) {
            setQuery(pageQuery)
        }
    }, [pageQuery])

    function didClickSearch() {
        if (query) {
            router.push(`/search?query=${query}`)
            if (onSearch) {
                onSearch()
            }
            thatchSegment.trackMiscEvent('search_performed', {
                query,
                source
            })
        }
    }

    function didClickIntoSearch() {
        thatchSegment.trackMiscEvent('search_input_clicked', {
            source
        })
    }

    return (
        <FocusTrap active={autofocus}>
            <Input
                data-autofocus
                enterKeyHint='search'
                placeholder={placeholder}
                value={query}
                onChange={(event) => setQuery(event.currentTarget.value)}
                onFocus={didClickIntoSearch}
                icon={<div className="pl-2"><SvgIcon type={SvgIconType.SEARCH} width={16} height={16} /></div>}
                rightSection={<div className='flex gap-2 items-center'>
                    {query && (
                        <CloseButton
                            onClick={() => setQuery('')}
                            aria-label="Clear search query"
                            variant="transparent"
                            size="md"
                            color='appPlaceholder.0'
                        />
                    )}
                    <ThatchButton label="Search" size="small" onClick={didClickSearch} className='!h-10' />
                </div>}
                classNames={{
                    input: 'rounded-full h-12 md:w-[470px] border-black/25 !pl-10',
                    rightSection: 'pr-[5px] r-0 w-auto',
                }}
                onKeyDown={getHotkeyHandler([
                    ['Enter', didClickSearch]
                ])}
            />
        </FocusTrap>
    )

}