import { Box, Center, Group, Menu, Modal, ScrollArea, createStyles } from '@mantine/core'
import { useLocalStorage } from '@mantine/hooks'
import { IconArrowRight, IconX } from '@tabler/icons'
import { useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'

import { MantineNextLink } from '~/components/shared/MantineNextLink'
import { ThatchAvatar } from '~/components/shared/ThatchAvatarDynamic'
import { SvgIcon, SvgIconType } from '~/components/shared/image/SvgIcon'
import { getImageUrl } from '~/components/shared/image/helpers'
import { LoadingSpinner } from '~/components/shared/loading/LoadingSpinnerDynamic'
import { Typography } from '~/components/shared/text/Typography'
import { fetchSubscriptionUpdates } from '~/endpoints/board'
import { BoardEvent } from '~/endpoints/model/board'
import { useScreenSize } from '~/hooks'
import {
  FIVE_SECONDS_IN_MILLI,
  LOCAL_STORAGE_MOST_RECENT_UPDATE,
  REACT_QUERY_SUBSCRIPTION_UPDATES_KEY,
  TEN_MINUTES_IN_MILLI,
} from '~/utils/constants'
import { timeAgo } from '~/utils/time'

const MENU_ITEMS_LENGTH = 8

interface SubscriptionUpdatesProps {
  color: string
}

interface DetailViewProps {
  onClose: () => void
}

const useStyle = createStyles(theme => ({
  dropdown: {
    padding: '27px 8px',
    width: 350,
  },
  closeIcon: {
    backgroundColor: theme.colors.appWhite,
    cursor: 'pointer',
    border: `1px solid rgba(0, 0, 0, 0.3)`,
    borderRadius: 24,
    width: 48,
    height: 48,
  },
  menuItem: {
    paddingLeft: 8,
    paddingRight: 8,
    ':hover': {
      textDecoration: 'none',
    },
  },
  divider: {
    margin: '12px 8px',
    borderColor: theme.colors.appPaper,
  },
  itemContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  itemContent: {
    display: 'flex',
    flex: 1,
    width: 230,
    alignItems: 'center',
  },
  detailsItemContainer: {
    display: 'block',
    borderBottom: `1px solid ${theme.colors.appPaper}`,
    padding: '36px 8px 36px 8px',
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      padding: '24px 4px 24px 4px',
    },
    ':hover': {
      textDecoration: 'none',
      backgroundColor: theme.colors.appYellow,
    },
    ':active': {
      backgroundColor: theme.colors.appYellow,
    },
    '::selection': {
      backgroundColor: theme.colors.appYellow,
    },
  },
  modalContainer: {
    backgroundColor: theme.colors.appPaper,
    height: 'calc(100vh - 68px)',
    padding: '60px 180px 0px 180px',
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      padding: 0,
      height: '100vh',
    },
  },
  modalContent: {
    height: '75vh',
    backgroundColor: theme.colors.appWhite,
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      height: '100vh',
    },
  },
  modalTitleContent: {
    padding: '0px 47px 35px 47px',
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      padding: '0px 20px 20px 20px',
    },
  },
  scrollArea: {
    height: '80%',
    padding: '0px 32px 0px 32px',
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      padding: '0px 16px 0px 16px',
    },
  },
  menuScrollArea: {
    height: '50vh',
    padding: '0px 8px 8px 8px',
  },
}))

const UpdateItem = ({
  item,
  inDetailView = false,
}: {
  item: BoardEvent
  idx: number
  inDetailView?: boolean
}) => {
  const { classes } = useStyle()
  const { isMobileScreen } = useScreenSize()
  const desktopSize = inDetailView ? 70 : 40
  const mobileSize = inDetailView ? 50 : 30
  const width = isMobileScreen ? mobileSize : desktopSize
  return (
    <Box className={classes.itemContainer}>
      <Box className={classes.itemContent}>
        <ThatchAvatar
          url={getImageUrl({ src: item.avatar, width })}
          desktopSize={desktopSize}
          mobileSize={mobileSize}
        ></ThatchAvatar>

        <Typography
          pl={inDetailView ? 12 : 8}
          variant={inDetailView ? 'title' : 'tag'}
          lineClamp={2}
        >
          {item.author} {item.event} {item.name}
        </Typography>
      </Box>
      <Box ml={8}>
        <Typography
          variant={inDetailView ? 'body3' : 'tag'}
          color="dimmed"
        >
          {timeAgo.format(new Date(item.timestamp))}
        </Typography>
      </Box>
    </Box>
  )
}

const DetailView: React.FC<DetailViewProps> = props => {
  const { classes } = useStyle()
  const {
    data: updates,
    refetch,
    isLoading,
  } = useQuery({
    queryKey: ['subscriptionUpdatesDetail'],
    queryFn: () => fetchSubscriptionUpdates(50),
    enabled: false, // only allow manual fetch
  })
  useEffect(() => {
    refetch()
  }, [refetch])

  return (
    <Box className={classes.modalContainer}>
      <Box
        pt={40}
        className={classes.modalContent}
      >
        <Group
          className={classes.modalTitleContent}
          position="apart"
        >
          <Typography variant="h3">Updates</Typography>
          <Center
            className={classes.closeIcon}
            onClick={props.onClose}
          >
            <IconX
              size={22}
              color="black"
            />
          </Center>
        </Group>
        <ScrollArea className={classes.scrollArea}>
          {isLoading ? (
            <Center m={50}>
              <LoadingSpinner color="black" />
            </Center>
          ) : (
            <>
              {updates && updates.length > 0 ? (
                updates.map((e, idx) => {
                  return (
                    <Box
                      key={`${e.event}-${idx}`}
                      component={MantineNextLink}
                      className={classes.detailsItemContainer}
                      href={`/guide/${e.token}/view`}
                      onClick={props.onClose}
                    >
                      <UpdateItem
                        item={e}
                        idx={idx}
                        inDetailView
                      />
                    </Box>
                  )
                })
              ) : (
                <Center m={50}>
                  <Typography variant="h3">No new updates</Typography>
                </Center>
              )}
            </>
          )}
        </ScrollArea>
      </Box>
    </Box>
  )
}

interface SubscriptionAllUpdatesProps {
  onClose: () => void
  isOpened: boolean
  style: React.CSSProperties
}

export const SubscriptionAllUpdates: React.FC<SubscriptionAllUpdatesProps> = props => {
  const rootStyle = { zIndex: 1001 }
  return (
    <Modal
      opened={props.isOpened}
      onClose={props.onClose}
      fullScreen
      padding={0}
      withCloseButton={false}
      styles={{
        root: props.style ? { ...props.style, ...rootStyle } : rootStyle,
        content: { paddingTop: 68, div: { maxHeight: '100%' } },
        body: { paddingRight: 0 },
      }}
    >
      <DetailView onClose={props.onClose} />
    </Modal>
  )
}

export const SubscriptionUpdates: React.FC<SubscriptionUpdatesProps> = props => {
  const { classes } = useStyle()
  const [detailOpened, setDetailOpened] = useState(false)
  const [menuOpened, setMenuOpened] = useState(false)
  const [showDot, setShowDot] = useState(false)
  const { data: updates, refetch } = useQuery({
    queryKey: [REACT_QUERY_SUBSCRIPTION_UPDATES_KEY],
    queryFn: () => fetchSubscriptionUpdates(10),
    staleTime: TEN_MINUTES_IN_MILLI,
    gcTime: TEN_MINUTES_IN_MILLI,
    retry: 3,
    retryDelay: FIVE_SECONDS_IN_MILLI,
  })
  const [recentUpdate, setRecentUpdate] = useLocalStorage({
    key: LOCAL_STORAGE_MOST_RECENT_UPDATE,
    defaultValue: '',
  })

  const openMenu = (isOpen: boolean) => {
    if (updates && updates.length > 0) {
      setRecentUpdate(updates[0].timestamp)
    }
    setMenuOpened(isOpen)
  }

  useEffect(() => {
    const current = updates ?? []
    if (menuOpened) {
      setShowDot(false)
    } else if (
      !menuOpened &&
      current.length > 0 &&
      recentUpdate &&
      current[0].timestamp != recentUpdate
    ) {
      // only show indicator if new entries are present in payload
      setShowDot(true)
    } else if (!menuOpened && current.length > 0 && !recentUpdate) {
      // only show indicator if no last recentUpdate cookie present
      setShowDot(true)
    } else {
      setShowDot(false)
    }
  }, [recentUpdate, updates, menuOpened])

  useEffect(() => {
    if (menuOpened) {
      refetch() // force fetch on menu open
    }
  }, [menuOpened, refetch])

  // TODO: verify is this needed on mobile or desktop
  // useEffect(() => {
  //   // disable background page scrolling when menu opened
  //   document.body.style.overflowY = menuOpened || detailOpened ? 'hidden' : 'visible'
  // }, [menuOpened, detailOpened])

  return (
    <Box sx={{ cursor: 'pointer', marginRight: -12 }}>
      {updates && (
        <SubscriptionAllUpdates
          isOpened={detailOpened}
          onClose={() => setDetailOpened(false)}
          style={{ top: 68 }} // top nav bar height
        />
      )}
      <Menu
        shadow="md"
        position="bottom-end"
        opened={menuOpened}
        onChange={openMenu}
      >
        <Menu.Target>
          <Center
            sx={theme => ({
              height: 40,
              width: 40,
              borderRadius: 20,
            })}
            className='border border-black/25'
          >
            <SvgIcon
              height={20}
              width={20}
              type={showDot ? SvgIconType.NEW_NOTIFICATION : SvgIconType.NOTIFICATION}
              fill={props.color}
            />
          </Center>
        </Menu.Target>

        {updates && updates.length > 0 && (
          <Menu.Dropdown className={classes.dropdown}>
            <ScrollArea className={classes.menuScrollArea}>
              {updates.slice(0, MENU_ITEMS_LENGTH).map((e, idx) => {
                return (
                  <Box key={`${e.event}-${idx}`}>
                    <Menu.Item
                      component={MantineNextLink}
                      href={`/guide/${e.token}/view`}
                      className={classes.menuItem}
                    >
                      <UpdateItem
                        item={e}
                        idx={idx}
                      />
                    </Menu.Item>
                    <Menu.Divider className={classes.divider} />
                  </Box>
                )
              })}

              {updates.length > 8 && (
                <Menu.Item
                  component="button"
                  py={8}
                  onClick={() => setDetailOpened(true)}
                >
                  <Group
                    position="center"
                    spacing="xs"
                  >
                    <Typography
                      variant="body3"
                      align="center"
                    >
                      See all notifications
                    </Typography>
                    <IconArrowRight size={18} />
                  </Group>
                </Menu.Item>
              )}
            </ScrollArea>
          </Menu.Dropdown>
        )}
      </Menu>
    </Box>
  )
}
